'use strict'

###*
 # @ngdoc service
 # @name lpa.lpaIncidentTracking.factory:ProposalRouteManager

 # @description
###

class ProposalRouteManagerFactory
  ### @ngInject ###
  constructor: (
    $log
    ProposalRoute
  ) ->
    ProposalRouteManagerBase = {}

    # PRIVATE
    ProposalRouteManagerBase.getRoute = (from, to) ->
      # Apply the filter on the GET
      params =
        'from': from
        'to': to
      return ProposalRoute.post(params)
      

    return ProposalRouteManagerBase

angular
  .module 'lpaIncidentTracking'
  .factory 'ProposalRouteManager', ProposalRouteManagerFactory
